import React, { useState } from 'react';
import { useRouter } from 'next/router'
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Dynamic imports
const SearchIcon = dynamic(() => import('../../../../public/assets/search'));
const Search = dynamic(() => import('../Search'));

const SearchComponent = ({ terms }) => {
  
  const s = require('./search.module.scss');
  
  const [opened, setOpened] = useState(false);

  const toggleBar = () => {
    setOpened(true);
  };

  return (
    <div className={s.SearchBar}>
      <div className={s.SearchBarIcon} onClick={() => setOpened(true)}>
        <SearchIcon />
      </div>
      <Search isOpen={opened} terms={terms} setOpened={setOpened} />
    </div>
  );
}

SearchComponent.propTypes = {
  isMenuOpen: PropTypes.bool,
}

export default SearchComponent;